import React, { useEffect, useRef, useState } from "react";
import Button from "../../elements/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  GitHub,
  LinkedIn,
  WhatsApp,
  AssignmentInd,
  Devices,
  CoffeeTwoTone,
} from "@mui/icons-material";
import LocomotiveScroll from "locomotive-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

const ProjectContent = () => {
  const rightRef = useRef(null);
  useEffect(() => {
    const locoScroll = new LocomotiveScroll({
      el: rightRef.current,
      smooth: true,
    });
    // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
    locoScroll.on("scroll", ScrollTrigger.update);
    // ScrollTrigger.scrollerProxy(".smooth-scroll", {
    // scrollTop(value) {
    //   return arguments.length
    //     ? locoScroll.scrollTo(value, 0, 0)
    //     : locoScroll.scroll.instance.scroll.y;
    // }, // we don't have to define a scrollLeft because we're only scrolling vertically.
    // getBoundingClientRect() {
    //   return {
    //     top: 0,
    //     left: 0,
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    //   };
    // },
    // });
  }, []);

  return (
    <div
      className="home-right"
      ref={rightRef}
      data-scroll
      data-scroll-container
      data-scroll-speed="4"
    >
      <div className="content-right">
        <div className="card-portfolio">
          {/* <div className="content-card"> */}
          <h1>Tb. Mega Utama</h1>
          <p className="deac-project">
            Create web app Tb.MegaUtama include ecommerce using NextJs, Zustand,
            and Strapi
          </p>
          <Button isPurple isSmall>
            {" "}
            visit
            <ArrowForwardIcon />
          </Button>
        </div>
        <div className="card-portfolio">
          {/* <div className="content-card"> */}
          <h1>Sneakers</h1>
          <p className="deac-project">
            Create web app commerce Sneakers using ReactJs, Redux Toolkit,
            Styled Component and React MUI, integrate API create using Nodejs,
            ExpressJS and MongoDB
          </p>
          <Button
            type="link"
            isExternal
            href={"https://fe-sneakers-five.vercel.app/"}
            isPurple
            isSmall
          >
            {" "}
            visit
            <ArrowForwardIcon />
          </Button>
        </div>
        <div className="card-portfolio">
          {/* <div className="content-card"> */}
          <h1>Traveler.com</h1>

          <p className="deac-project">
            Create web app traverler.com using ReactJs, Redux Toolkit, Styled
            Component,react-datepicker and React MUI, integrate API create using
            Nodejs, ExpressJS and MongoDB
          </p>
          <Button
            type="link"
            isExternal
            href={"https://fe-booking-app-id.vercel.app"}
            isPurple
            isSmall
          >
            {" "}
            visit
            <ArrowForwardIcon />
          </Button>
        </div>

        <div className="card-content">
          <p className="title-content"> Explore</p>

          <div className="card-wrapp">
            <div className="icon-card">
              <GitHub sx={{ fontSize: 50 }} />
            </div>
            <div className="card-desc">
              <h1>github</h1>
              <h2>collection of my code</h2>
            </div>
          </div>
          <div className="card-wrapp">
            <div className="icon-card">
              <LinkedIn sx={{ fontSize: 50 }} />
            </div>
            <div className="card-desc">
              <h1>Linkedin</h1>
              <h2>my profile is on linkedin</h2>
            </div>
          </div>
          <div className="card-wrapp">
            <div className="icon-card">
              <WhatsApp sx={{ fontSize: 50 }} />
            </div>
            <div className="card-desc">
              <h1>Whatsapp</h1>
              <h2>send a message</h2>
            </div>
          </div>
        </div>

        <div className="card-content">
          <div className="title-content">more</div>
          <Button
            isOrange
            isLarge
            style={{ marginBottom: "50px" }}
            type="link"
            href="/project"
          >
            Project <Devices sx={{ fontSize: 38 }} />
          </Button>
          <Button isLarge isBlue style={{ marginBottom: "50px" }}>
            Contact <AssignmentInd sx={{ fontSize: 38 }} />
          </Button>
          <Button isLarge isPurple type="link" href="/about">
            About <AssignmentInd sx={{ fontSize: 38 }} />
          </Button>
        </div>
        <div className="card-content">
          <div className="title-content">
            <p>
              made with <CoffeeTwoTone /> by Bisma Reza Ferdian{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectContent;
