import gsap, { ScrollTrigger } from "gsap/all";
import React, { useRef } from "react";
import { useEffect } from "react";

function Contact() {
  const seactionRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.set(".data", {
      rotation: -90,
      transformOrigin: "center",
      drawSVG: "0",
    });

    var action = gsap
      .timeline({
        defaults: { duration: 2, ease: "none" },
        scrollTrigger: {
          trigger: "section",
          pin: true,
          scrub: true,
          start: "top -1px",
          end: "+=1000",
          markers: true,
          onUpdate: (self) => {
            var no = self.progress.toFixed(2) * 100;
            gsap.set(".text", { textContent: no });
            //gsap.set(".text", {textContent:no * 100 + '%'})
          },
        },
      })
      .set(".text", { autoAlpha: 1 });
    action.to(".data", { drawSVG: "100%", duration: 3, ease: "none" });
  }, []);

  return (
    <div>
      <section ref={seactionRef}></section>

      <svg
        id="progress"
        viewBox="0 0 300 200"
        xlink="http://www.w3.org/1999/xlink"
      >
        <circle class="data" cx="150" cy="55" r="40" stroke="green" />

        <text class="text" y="62" x="150" text-anchor="middle">
          xx
        </text>
      </svg>
    </div>
  );
}

export default Contact;
