import React, { useEffect, useRef } from "react";
import Button from "../../elements/Button";
import "./home.scss";
// import EmailIcon from "@mui/icons-material/Email";
// import GitHubIcon from "@mui/icons-material/GitHub";\
import SouthIcon from "@mui/icons-material/South";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { Email, GitHub, Instagram } from "@mui/icons-material";
import EastIcon from "@mui/icons-material/East";
import { gsap, ScrollTrigger } from "gsap/all";
import RightCard from "./RightCard";
import { Link, useLocation } from "react-router-dom";

const Home = () => {
  const rightRef = useRef(null);
  const leftRef = useRef(null);
  const containerRef = useRef(null);
  const homeContainerRef = useRef(null);

  let location = useLocation();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    function stopTrigger() {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: leftRef.current,
          start: "top top",
          // end: `+=${containerRef.current.offsetHight}`,
          end: "bottom bottom",
          // end: "top 20%",
          pin: true,
          scrub: true,
          markers: true,
        },
      });

      return tl;
    }

    const master = gsap.timeline();
    master.add(stopTrigger());
  }, []);

  return (
    <div
      className="container "
      id="content"
      ref={containerRef}
      style={{ transform: "translateY (-1000)" }}
    >
      <div className="home-container" id="home" ref={homeContainerRef}>
        {/* <div className="home-wrapp " ref={homeRef}> */}
        <div
          className="home-left"
          // data-scroll
          // data-scroll-sticky
          // data-scroll-target="#home"
          ref={leftRef}
        >
          <div className="content-left">
            <p className="title">Hello, i'am </p>
            <h1>
              Bisma Reza <br />
              Ferdian
            </h1>
            <p className="h2">Front-end developer</p>
            <p className="desc">
              welcome to my portofolio <br />
              let’s see what I do and contact me.{" "}
            </p>

            <Button isSmall isRed>
              <Email />
              email
            </Button>
            <div className="navigation">
              <Button
                href="https://www.instagram.com/bismarezaferdian/"
                type="link"
                isNone
                isExternal
              >
                <Instagram /> Instagram
              </Button>
              <Button
                href="https://github.com/Bismarezaferdian"
                type="link"
                isNone
                isExternal
              >
                <GitHub /> Github
              </Button>
            </div>
          </div>
        </div>
        <div ref={rightRef}>
          <RightCard />
        </div>
        <div className="navigation-home">
          <Link to="/">
            <button
              className={`home-navigate ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              Home
            </button>
          </Link>
          <Link to="/about">
            <button className="about-navigate ">About</button>
          </Link>
          <div className="wrapp-arrow">
            <EastIcon className="arrow" />
          </div>
        </div>
      </div>

      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default Home;
