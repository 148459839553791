import React from "react";
import { Link } from "react-router-dom";
import gsap, { TweenMax, TimelineMax, Power3, Power4 } from "gsap";
import { useRef, useEffect } from "react";

const One = () => {
  let screen = useRef(null);
  let body = useRef(null);
  useEffect(() => {
    var tl = new TimelineMax();
    // const tl = gsap.timeline();

    tl.to(screen, {
      duration: 1.2,
      height: "0",
      ease: Power3.easeInOut,
    });
    tl.to(screen, {
      duration: 1,
      bottom: "100%",
      ease: Power3.easeInOut,
      delay: 0.3,
    });
    tl.set(screen, { left: "-100%" });
    TweenMax.to(body, 0.3, {
      css: {
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut,
      },
    }).delay(2);
    return () => {
      TweenMax.to(body, 1, {
        css: {
          opacity: "0",
          pointerEvents: "none",
        },
      });
    };
  });
  return (
    <div>
      <div className="load-container">
        <div className="load-screen1" ref={(el) => (screen = el)}>
          <h1>test</h1>
        </div>
      </div>
      <div data-barba="container" className="Contact">
        <div ref={(el) => (body = el)} className="Headd">
          <div> Contact Me!!!</div>
          <Link to="/two" className="button">
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default One;
