import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import propTypes from "prop-types";

const Button = (props) => {
  const className = [props.className];
  if (props.isRed) className.push("btn-red");
  if (props.isOrange) className.push("btn-orange");
  if (props.isBlue) className.push("btn-blue");
  if (props.isPurple) className.push("btn-purple");
  if (props.isSmall) className.push("btn-small");
  if (props.isMedium) className.push("btn-medium");
  if (props.isLarge) className.push("btn-large");
  if (props.isNone) className.push("btn-none");
  if (props.isDisabled) className.push("disabled");

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  if (props.type === "link") {
    if (props.isExternal) {
      return (
        <a
          href={props.href}
          className={className.join(" ")}
          style={props.style}
          //pr
          // target={props.target === "_blank" ? "_blank" : undefined}
          // rel={props.target === "_blank" ? "noopener" : undefined}
          // target={props.target === "_blank" ? "_blank" : undefined}
          // rel={props.target === "_blank" ? "noopener noreferrer" : undefined}
        >
          {props.children}
        </a>
      );
    } else {
      return (
        <Link
          to={props.href}
          className={className.join(" ")}
          style={props.style}
          onClick={onClick}
        >
          {props.children}
        </Link>
      );
    }
  }

  return (
    <button
      className={className.join(" ")}
      style={props.style}
      onClick={onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </button>
  );
};

export default Button;

// Button.propTypes = {
//   type: propTypes.oneOf(["button", "link"]),
// };
