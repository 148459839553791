import React, { useEffect, useRef, useState } from "react";
import Button from "../../elements/Button";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  GitHub,
  LinkedIn,
  WhatsApp,
  AssignmentInd,
  Devices,
  CoffeeTwoTone,
} from "@mui/icons-material";
import LocomotiveScroll from "locomotive-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

const AboutContent = () => {
  const rightRef = useRef(null);
  useEffect(() => {
    const locoScroll = new LocomotiveScroll({
      el: rightRef.current,
      smooth: true,
    });
    // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
    locoScroll.on("scroll", ScrollTrigger.update);
    // ScrollTrigger.scrollerProxy(".smooth-scroll", {
    // scrollTop(value) {
    //   return arguments.length
    //     ? locoScroll.scrollTo(value, 0, 0)
    //     : locoScroll.scroll.instance.scroll.y;
    // }, // we don't have to define a scrollLeft because we're only scrolling vertically.
    // getBoundingClientRect() {
    //   return {
    //     top: 0,
    //     left: 0,
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    //   };
    // },
    // });
  }, []);

  return (
    <div
      className="about-right"
      ref={rightRef}
      data-scroll
      data-scroll-container
      data-scroll-speed="4"
    >
      <div className="about-content-right">
        <div className="card-information">
          <p className="title">01. What is your name </p>
          <span className="desc-about">Bisma Reza Ferdian</span>
        </div>
        <div className="card-information">
          <p className="title">02. Where are you from </p>
          <span className="desc-about">Jakarta</span>
        </div>
        <div className="card-information">
          <p className="title">03. graduation from </p>
          <span className="desc-about">
            Bachelor’s degree in UBSI program study Information System, graduate
            with IPK 3,27
          </span>
        </div>
        <div className="card-information">
          <p className="title">04. skill </p>
          <span className="desc-about">
            {" "}
            <ArrowRightIcon /> ReactJs
          </span>
          <span className="desc-about">
            <ArrowRightIcon />
            NextJs Js
          </span>
          <span className="desc-about">
            <ArrowRightIcon />
            Redux Toolkit
          </span>
          <span className="desc-about">
            <ArrowRightIcon />
            Zustand
          </span>
          <span className="desc-about">
            <ArrowRightIcon />
            ExpressJs(monggoDB)
          </span>
          <span className="desc-about">
            <ArrowRightIcon />
            Strapi(headless CMS)
          </span>
          <span className="desc-about">
            <ArrowRightIcon />
            Tailwind css
          </span>
          <span className="desc-about">
            <ArrowRightIcon />
            Styled Component
          </span>
        </div>

        <div className="card-content">
          <p className="title-content"> Explore</p>

          <div className="card-wrapp">
            {/* <Button type="link" to="https://github.com/Bismarezaferdian"> */}
            <Button
              isNone
              type="link"
              isExternal
              href="https://github.com/Bismarezaferdian"
              className="icon-card"
            >
              <GitHub sx={{ fontSize: 50 }} />
            </Button>
            {/* </Button> */}
            <div className="card-desc">
              <h1>github</h1>
              <h2>collection of my code</h2>
            </div>
          </div>
          <div className="card-wrapp">
            <Button
              isNone
              type="link"
              isExternal
              href="https://www.linkedin.com/in/bisma-reza-ferdian-4110731a8/"
              className="icon-card"
            >
              <LinkedIn sx={{ fontSize: 50 }} />
            </Button>
            <div className="card-desc">
              <h1>Linkedin</h1>
              <h2>my profile is on linkedin</h2>
            </div>
          </div>
          {/* https://wa.me/+6282211666095 */}
          <div className="card-wrapp">
            <Button
              isNone
              type="link"
              isExternal
              href="https://wa.me/+6282211666095"
              className="icon-card"
            >
              <WhatsApp sx={{ fontSize: 50 }} />
            </Button>
            <div className="card-desc">
              <h1>Whatsapp</h1>
              <h2>send a message</h2>
            </div>
          </div>
        </div>

        <div className="card-content">
          <div className="title-content">more</div>
          <Button
            isLarge
            isOrange
            style={{ marginBottom: "50px" }}
            type="link"
            href="/project"
          >
            Project <Devices sx={{ fontSize: 38 }} />
          </Button>
          <Button isLarge isBlue>
            Contact <AssignmentInd sx={{ fontSize: 38 }} />
          </Button>
        </div>
        <div className="card-content">
          <div className="title-content">
            <p>
              made with <CoffeeTwoTone /> by Bisma Reza Ferdian{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
