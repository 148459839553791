// import logo from "./logo.svg";
// import "./App.css";

import gsap from "gsap";
import { Expo } from "gsap/all";
import ScrollTrigger from "gsap/ScrollTrigger";
import TextPlugin from "gsap/TextPlugin";
import { useEffect } from "react";
import About from "../components/About";
import Home from "../components/Home";
// import Home from "./components/Home";
// import Button from "./elements/Button";

function AboutPage() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(TextPlugin);
    const tl = gsap.timeline();
    tl.to(".target", { duration: 0.5, delay: 0.5, text: "About" });
    tl.to(".loading", {
      opacity: 1,
      y: -1000,
      ease: Expo.easeInOut,
      delay: 0.5,
      // // x: -1000,
    });
  }, []);

  return (
    <div>
      <div className="loading">
        <p className="target"></p>
      </div>
      <About />
    </div>
  );
}

export default AboutPage;
