import React, { useEffect, useRef } from "react";
import Button from "../../elements/Button";
import "./about.scss";
// import EmailIcon from "@mui/icons-material/Email";
// import GitHubIcon from "@mui/icons-material/GitHub";
import { Email, GitHub, Instagram } from "@mui/icons-material";
import { gsap, Power4, ScrollTrigger, TextPlugin } from "gsap/all";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Power3 } from "gsap";
import { TimelineMax } from "gsap/gsap-core";
import { TweenMax } from "gsap/gsap-core";
import RightCard from "../Home/RightCard";
import AboutContent from "./AboutContent";
// import RightCard from "./RightCard";

const About = () => {
  const rightRef = useRef(null);
  const leftRef = useRef(null);
  const containerRef = useRef(null);
  const homeContainerRef = useRef(null);
  // const navigateRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    function stopTrigger() {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: leftRef.current,
          start: "top top",
          // end: `+=${containerRef.current.offsetHight}`,
          end: "bottom bottom",
          pin: true,
          scrub: true,
          // markers: true,
        },
      });

      return tl;
    }

    const master = gsap.timeline();
    master.add(stopTrigger());
  }, []);

  //barbajs
  // useEffect(() => {
  //   var tl = new TimelineMax();
  //   tl.to(homeContainerRef, {
  //     duration: 1.2,
  //     height: "100%",
  //     ease: Power3.easeInOut,
  //   });
  //   tl.to(homeContainerRef, {
  //     duration: 1,
  //     top: "100%",
  //     ease: Power3.easeInOut,
  //     delay: 0.3,
  //   });
  //   tl.set(homeContainerRef, { left: "-100%" });
  //   TweenMax.to(navigateRef, 0.3, {
  //     css: {
  //       opacity: "1",
  //       pointerEvents: "auto",
  //       ease: Power4.easeInOut,
  //     },
  //   }).delay(2);
  //   return () => {
  //     TweenMax.to(navigateRef, 1, {
  //       css: {
  //         opacity: "0",
  //         pointerEvents: "none",
  //       },
  //     });
  //   };
  // });
  return (
    <div
      className="container-about "
      id="content"
      ref={containerRef}
      // style={{ transform: "translateY (-1000)" }}
    >
      <div className="about-container" id="home" ref={homeContainerRef}>
        {/* <div className="home-wrapp " ref={homeRef}> */}
        <div
          className="about-left"
          // data-scroll
          // data-scroll-sticky
          // data-scroll-target="#home"
          ref={leftRef}
        >
          <div className="content-about-left">
            <p className="title-about">Hello there,</p>
            <h1>
              Let me introduce <br />
              my self
            </h1>
            {/* <p className="h2">Front-end developer</p> */}
            <p className="desc">
              {/* I a’m Front-end developer, experience with React Js and Node
              Js,and Strapi */}
              I'm a junior Front-end Developer based in Jakarta, Graduated from
              UBSI program study information system, who likes coding, and
              learning new things for a better future.
            </p>

            <div className="about-btn-email">
              <Button isSmall isRed>
                <Email />
                email
              </Button>
            </div>
            <div className="navigation-about">
              <Button
                href="https://www.instagram.com/bismarezaferdian/"
                type="link"
                isNone
                isExternal
              >
                <Instagram /> Instagram
              </Button>
              <Button
                href="https://github.com/Bismarezaferdian"
                type="link"
                isNone
                isExternal
              >
                <GitHub /> Github
              </Button>
            </div>
          </div>
        </div>
        <div ref={rightRef}>
          <AboutContent />
        </div>
        {/* navigation */}
        <div className="navigation-home">
          <Link to="/">
            <button
              className={`home-navigate ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              Home
            </button>
          </Link>
          <Link to="/about">
            <button
              className={`about-navigate ${
                location.pathname === "/about" ? "active" : ""
              }`}
            >
              About
            </button>
          </Link>
        </div>
      </div>

      {/* </div> */}
    </div>
  );
};

export default About;
