import React, { useEffect, useRef, useState } from "react";
import Button from "../../elements/Button";
import "./home.scss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  GitHub,
  LinkedIn,
  WhatsApp,
  AssignmentInd,
  Devices,
  CoffeeTwoTone,
} from "@mui/icons-material";
import LocomotiveScroll from "locomotive-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import gsap from "gsap/all";

const RightCard = () => {
  const rightRef = useRef(null);

  useEffect(() => {
    const locoScroll = new LocomotiveScroll({
      el: rightRef.current,
      smooth: true,
    });
    // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
    locoScroll.on("scroll", ScrollTrigger.update);
    // ScrollTrigger.scrollerProxy(".smooth-scroll", {
    // scrollTop(value) {
    //   return arguments.length
    //     ? locoScroll.scrollTo(value, 0, 0)
    //     : locoScroll.scroll.instance.scroll.y;
    // }, // we don't have to define a scrollLeft because we're only scrolling vertically.
    // getBoundingClientRect() {
    //   return {
    //     top: 0,
    //     left: 0,
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    //   };
    // },
    // });
  }, []);

  return (
    <div
      className="home-right"
      ref={rightRef}
      data-scroll
      data-scroll-container
      data-scroll-speed="4"
    >
      <div className="content-right">
        <div className="card-portfolio">
          {/* <div className="content-card"> */}
          <h1>
            let's explore my <br /> <span className="h2">portfolio</span>
          </h1>
          <Button isPurple isSmall>
            {" "}
            <span style={{ fontSize: "18px" }}>see more</span>
            {/* see more */}
            <ArrowForwardIcon className="arrow" />
          </Button>
        </div>

        <div className="card-content">
          <p className="title-content"> Explore</p>

          <div className="card-wrapp">
            {/* <Button type="link" to="https://github.com/Bismarezaferdian"> */}
            <Button
              isNone
              type="link"
              isExternal
              href="https://github.com/Bismarezaferdian"
              className="icon-card"
            >
              <GitHub sx={{ fontSize: 50 }} />
            </Button>
            {/* </Button> */}
            <div className="card-desc">
              <h1>github</h1>
              <h2>collection of my code</h2>
            </div>
          </div>
          <div className="card-wrapp">
            <Button
              isNone
              type="link"
              isExternal
              href="https://www.linkedin.com/in/bisma-reza-ferdian-4110731a8/"
              className="icon-card"
            >
              <LinkedIn sx={{ fontSize: 50 }} />
            </Button>
            <div className="card-desc">
              <h1>Linkedin</h1>
              <h2>my profile is on linkedin</h2>
            </div>
          </div>
          {/* https://wa.me/+6282211666095 */}
          <div className="card-wrapp">
            <Button
              isNone
              type="link"
              isExternal
              href="https://wa.me/+6282211666095"
              className="icon-card"
            >
              <WhatsApp sx={{ fontSize: 50 }} />
            </Button>
            <div className="card-desc">
              <h1>Whatsapp</h1>
              <h2>send a message</h2>
            </div>
          </div>
        </div>

        <div className="card-content">
          <div className="title-content">more</div>
          <Button isLarge isOrange type="link" href="/project">
            Project <Devices sx={{ fontSize: 38 }} />
          </Button>
          <Button isLarge isBlue style={{ marginBottom: "50px" }}>
            Contact <AssignmentInd sx={{ fontSize: 38 }} />
          </Button>
          <Button isLarge isPurple type="link" href="/about">
            About <AssignmentInd sx={{ fontSize: 38 }} />
          </Button>
        </div>
        <div className="card-content">
          <div className="title-content">
            <p>
              made with <CoffeeTwoTone /> by Bisma Reza Ferdian{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightCard;
