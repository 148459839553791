import React, { useEffect, useRef } from "react";
import Button from "../../elements/Button";
import "./Project.scss";
// import EmailIcon from "@mui/icons-material/Email";
// import GitHubIcon from "@mui/icons-material/GitHub";
import { Email, GitHub, Instagram } from "@mui/icons-material";
import { gsap, ScrollTrigger } from "gsap/all";
import { Link } from "react-router-dom";
import ProjectContent from "./ProjectContent";
// import RightCard from "./RightCard";

const Project = () => {
  const rightRef = useRef(null);
  const leftRef = useRef(null);
  const containerRef = useRef(null);
  const homeContainerRef = useRef(null);
  const navigateRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    function stopTrigger() {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: leftRef.current,
          start: "top top",
          // end: `+=${containerRef.current.offsetHight}`,
          end: "top 20%",
          // end: "+=500",
          pin: true,
          scrub: true,
          //   markers: true,
        },
      });

      return tl;
    }

    const master = gsap.timeline();
    master.add(stopTrigger());
  }, []);

  //barbajs
  // useEffect(() => {
  //   var tl = new TimelineMax();
  //   tl.to(homeContainerRef, {
  //     duration: 1.2,
  //     height: "100%",
  //     ease: Power3.easeInOut,
  //   });
  //   tl.to(homeContainerRef, {
  //     duration: 1,
  //     top: "100%",
  //     ease: Power3.easeInOut,
  //     delay: 0.3,
  //   });
  //   tl.set(homeContainerRef, { left: "-100%" });
  //   TweenMax.to(navigateRef, 0.3, {
  //     css: {
  //       opacity: "1",
  //       pointerEvents: "auto",
  //       ease: Power4.easeInOut,
  //     },
  //   }).delay(2);
  //   return () => {
  //     TweenMax.to(navigateRef, 1, {
  //       css: {
  //         opacity: "0",
  //         pointerEvents: "none",
  //       },
  //     });
  //   };
  // });
  return (
    <div
      data-barba="container"
      className="container "
      id="content"
      ref={containerRef}
      style={{ transform: "translateY (-1000)" }}
    >
      <div className="home-container" id="home" ref={homeContainerRef}>
        {/* <div className="home-wrapp " ref={homeRef}> */}
        <div
          className="home-left"
          // data-scroll
          // data-scroll-sticky
          // data-scroll-target="#home"
          ref={leftRef}
        >
          <div className="content-left">
            <p className="title">hi, This is my</p>
            <h1>Project</h1>
            <p className="desc">
              my project list from personal experiments and freelance, with
              Teach stack ReactJs,NextJs,Redux,
              Zustand,ExpressJs,Strapi(Headless CMS)and Tailwind css,
            </p>

            <Button isSmall isRed>
              <Email />
              email
            </Button>
            <div className="navigation">
              <Button
                href="https://www.instagram.com/bismarezaferdian/"
                type="link"
                isNone
                isExternal
              >
                <Instagram /> Instagram
              </Button>
              <Button
                href="https://github.com/Bismarezaferdian"
                type="link"
                isNone
                isExternal
              >
                <GitHub /> Github
              </Button>
            </div>
          </div>
        </div>
        <div ref={rightRef}>
          <ProjectContent />
        </div>
        <div className="navigation-project">
          <Link to="/">
            <button className="home-navigate">Home</button>
          </Link>
          <Link to="/about">
            <button className="about-navigate ">About</button>
          </Link>
        </div>
      </div>

      {/* </div> */}
    </div>
  );
};

export default Project;
