// import logo from "./logo.svg";
// import "./App.css";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import TextPlugin from "gsap/TextPlugin";
import { useEffect } from "react";
import { Expo } from "gsap/all";

import Home from "../components/Home";
// import Home from "./components/Home";
// import Button from "./elements/Button";

function App() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(TextPlugin);
    const tl = gsap.timeline();
    tl.to(".target", { duration: 0.1, delay: 0.5, text: "Hello" });
    tl.to(".target", { duration: 0.1, delay: 0.5, text: "Hey" });
    tl.to(".target", { duration: 0.1, delay: 0.5, text: "olá" });
    tl.to(".target", { duration: 0.1, delay: 0.5, text: "Kon nichiwa" });
    tl.to(".target", {
      duration: 0.1,
      delay: 0.5,
      text: "Annyeong haseyo",
    });
    tl.to(".target", { duration: 0.1, delay: 0.5, text: "Hallo ;)" });
    tl.to(".loading", {
      opacity: 1,
      ease: Expo.easeInOut,

      y: -1000,
    });
  }, []);

  return (
    <div>
      <div className="loading">
        <p className="target"></p>
      </div>
      <Home />
    </div>
  );
}

export default App;
