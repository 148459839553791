import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import One from "./pages/One";
import Two from "./pages/ProjectPage";
// import "./App.css";
import "./Styles/index.scss";
import TextPlugin from "gsap/TextPlugin";
import { gsap, ScrollTrigger } from "gsap/all";
import ProjectPage from "./pages/ProjectPage";
import Contact from "./components/Contact";

const App = () => {
  // useEffect(() => {
  //   //   // gsap.registerPlugin(ScrollTrigger);
  //   gsap.registerPlugin(TextPlugin);
  //   //   // gsap.to("#content", { duration: 0.5, delay: 1, text: "Hii" });
  //   //   // gsap.to("#content", { duration: 0.5, delay: 2, text: "Hiiaaa" });
  //   //   // gsap.to("#content", { duration: 0.5, delay: 3, text: "Hiiiiiii" });
  //   //   // gsap.to("#content", { duration: 0.5, delay: 4, text: "Hiiiiiii" });
  //   const tl = gsap.timeline();
  //   tl.to(".target", { duration: 0.1, delay: 0.5, text: "Hello" });
  //   tl.to(".target", { duration: 0.1, delay: 0.5, text: "Hey" });
  //   tl.to(".target", { duration: 0.1, delay: 0.5, text: "olá" });
  //   tl.to(".target", { duration: 0.1, delay: 0.5, text: "Kon nichiwa" });
  //   tl.to(".target", { duration: 0.1, delay: 0.5, text: "Annyeong haseyo" });
  //   tl.to(".target", { duration: 0.1, delay: 0.5, text: "Hallo ;)" });
  //   tl.to(".loading", {
  //     opacity: 1,
  //     y: -1000,
  //   });
  // }, []);

  return (
    <>
      <div className="App" data-barba="container">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/one" element={<One />} />
          <Route path="/project" element={<ProjectPage />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/footer" element={<Footer />} /> */}
        </Routes>
      </div>
    </>
  );
};

export default App;
